"use client"

import classNames from "classnames"
import { usePathname } from "next/navigation"

import * as styles from "./MenuItem.css"
import Link from "./system/Link"
import Text from "./system/Text"

type MenuItemProps = {
	label: string
	url: string
}
export default function MenuItem({ label, url }: MenuItemProps) {
	const pathname = usePathname()
	let isCurrent = pathname === url || (url !== "/" && pathname.startsWith(url))
	return (
		<Text baseline color="strong" component="li">
			<Link
				href={url}
				color="inherit"
				highlight="none"
				underline="never"
				className={classNames(styles.menuItem, {
					[styles.selected]: isCurrent,
				})}
			>
				{label}
			</Link>
		</Text>
	)
}
