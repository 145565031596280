import classNames from "classnames"
import NextLink, { LinkProps as NextLinkProps } from "next/link"
import * as React from "react"

import * as styles from "./Link.css"

export type LinkProps = styles.LinkVariant & {
	children?: React.ReactNode
	className?: string
} & NextLinkProps

export default function Link({
	underline = "always",
	highlight = "hover",
	color = "inherit",
	children,
	className,
	...restProps
}: LinkProps) {
	return (
		<NextLink
			{...restProps}
			className={classNames(
				styles.link({
					underline,
					highlight,
					color,
				}),
				className,
			)}
		>
			{children}
		</NextLink>
	)
}
